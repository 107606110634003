@import "../Foundation/_mediaqueries";
@import "../Foundation/fontsize";

.l-footer {
  position: relative;

  .info {
    text-align: center;

    @include pc {
      text-align: left;
    }

    .name {
      color: #fff;
      font-size: 14px;
      margin-bottom: 5px;

      @include pc {
        font-size: 16px;
        margin-bottom: 14px;
      }
    }

    address {
      color: #fff;
      font-size: 13px;
      line-height: calc(19 / 13);
      margin-bottom: 5px;

      @include pc {
        font-size: 14px;
      }
    }

    .map-link {
      color: #fff;
      text-align: center;
      text-decoration: underline;
      font-size: 14px;
      display: block;
      margin-bottom: 30px;

      @include pc {
        text-align: left;
      }
    }

  }

  .nav {
    padding: 70px 20px 50px;
    background: #000000;

    @include pc {
      padding: 150px 0 100px;
    }

    .nav-inner {
      @include pc {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        max-width: 1100px;
        justify-content: space-between;
      }
    }


    .logo {
      width: 200px;
      margin: 0 auto 20px;

      @include pc {
        width: 250px;
        margin: 0 0 20px 0;
      }
    }

    ul {
      @include pc {
        margin-right: 70px;
      }

      &:last-child {
        li {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      li {
        margin-bottom: 1em;

        a {
          font-size: 14px;
          color: $color-light;
          font-weight: 500;
          transition: all 0.4s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .nav-sp {
    ul {
      li {
        a {
          font-size: 18px;
        }
      }
    }
  }

  .nav-pc {
    display: none;

    @include pc {
      display: flex;
    }

    ul {
      &:last-child {
        margin-right: 0;
      }

      .main-midashi {
        margin-bottom: 2em;

        &>a {
          display: flex;
          align-items: center;
          font-size: 20px;

          &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            background: #fff;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        ul {
          margin-top: 24px;

          li {
            a {
              font-size: 13px;
              display: flex;
              align-items: center;

              &::before {
                content: '';
                display: block;
                width: 10px;
                height: 1px;
                background: #fff;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }

  .other-nav {
    background: #000;
    padding: 0;

    @include pc {
      margin: 0 auto;
      padding: 18px 0;
      border-top: 1px solid #fff;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include pc {
        max-width: 1100px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: inherit;
      }

      li {
        margin-right: 10px;

        @include pc {
          border-bottom: none;
          border-right: 1px solid $color-default;
          margin-bottom: 0;
          padding: 0;
          line-height: 1;
          margin-right: 20px;
          padding-right: 20px;
        }

        &:nth-child(3) {
          margin-right: 0;
        }

        &:last-child {
          font-size: 10px;
          padding: 15px 0;
          border-top: 1px solid #fff;
          color: #fff;
          margin: 50px 0 0;
          width: 100%;
          text-align: center;

          @include pc {
            margin-left: auto;
            width: auto;
            border: none;
            margin: 0 0 0 auto;
            padding: 0;
          }
        }

        a {
          font-size: 13px;
          color: #fff;
          transition: all 0.4s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}