@import "../Foundation/_mediaqueries";

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

body.is-drawerActive .l-header .inner .logo-wrap {
  opacity: 0;
}

body.is-drawerActive {
  .l-header {
    &.is-top {
      .humburger-wrap {
        .hamburger {
          &::before {
            content: 'CLOSE';
            display: block;
            font-size: 10px;
            color: $color-default;
            position: absolute;
            transform: translateX(-50%);
            left: 50%;
            bottom: 0;
          }
        }
      }
    }
  }
}

.l-header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  border-bottom: 1px solid $color-default;

  .inner {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include pc() {
      padding: 18px 4.5vw;
    }

    &::before {
      content: '';
      display: block;
      width: 30px;

      @include pc {
        display: none;
      }
    }
  }

  .logo-wrap {
    position: relative;
    z-index: 9999;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      width: auto;
      height: 30px;

      @include pc {
        height: 45px;
      }
    }
  }

  .nav_wrapper {
    @include pc {
      display: flex;
      align-items: center;
    }
  }

  .nav-line {
    @include pc {
      ul {
        display: flex;
        align-items: center;
        border-radius: 30px;
        margin-right: 30px;

        li {
          a {
            color: $color-default;
            font-size: clamp(0.8vw, 1.1vw, 16px);
            display: block;
            margin-right: 3vw;
            padding: 5px 0;
            position: relative;

            &.nav-contact {
              background: #000;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 15px 10px 36px;
              line-height: 1;
              margin-right: 0;
              width: auto;

              &:hover {
                &::after {
                  background: url(../img/common/icon-mail-black.png) no-repeat center top/cover;
                }
              }

              &::after {
                content: '';
                display: block;
                width: 18px;
                height: 12px;
                background: url(../img/common/icon-mail.png) no-repeat center top/cover;
                border-radius: inherit;
                position: absolute;
                left: 10px;
                transform: translateY(-50%);
              }
            }
          }

          &.under {
            a {
              &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0%;
                width: 100%;
                height: 2px;
                background: #000;
                transition: all .3s;
                transform: scale(0, 1);
                transform-origin: center top;
              }

              &:hover {
                &::before {
                  transform: scale(1, 1);
                }
              }
            }
          }

          &.pulldown {
            position: relative;

            a {
              display: flex;
              align-items: center;

              &::after {
                content: '';
                display: inline-block;
                width: 6px;
                height: 6px;
                border-bottom: 1px solid #000;
                border-right: 1px solid #000;
                transform: rotate(45deg);
                margin-left: 10px;
              }
            }

            &:hover {
              ul {
                opacity: 1;
                visibility: visible;
              }
            }

            ul {
              opacity: 0;
              visibility: hidden;
              background: #fff;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.16);
              position: absolute;
              top: 40px;
              width: 100%;
              left: 0;
              border-radius: inherit;
              margin-right: 0;
              padding: 3px 8px;
              transition: all 0.6s ease;
              display: block;

              li {
                a {
                  color: #000;
                  display: block;
                  margin-right: 0;
                  font-size: 0.78vw;
                  letter-spacing: normal;

                  &::before,
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

        }
      }
    }
  }

  .humburger-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include pc() {
      display: block;
    }

    .button-wrap {
      position: relative;
      display: flex;
      align-items: center;
    }

    .button {
      position: relative;
      display: inline-block;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 100;
    }

    .hamburger {
      width: 40px;
      height: 30px;
      border: none;
      background: none;

      &::before {
        content: 'MENU';
        display: block;
        font-size: 10px;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: 0;
      }
    }

    .hamburger-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 40px;
      height: 1px;
      background-color: transparent;
      transition: inherit;
    }

    .hamburger-line::before,
    .hamburger-line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: $color-default;
      content: "";
      transition: inherit;
      left: 50%;
      transform: translateX(-50%);
    }

    .hamburger-line::before {
      width: 40px;
      top: -5px;
    }

    .hamburger-line::after {
      width: 40px;
      top: -12px;
    }

    .hamburger[aria-expanded="true"] {
      &::before {
        content: 'CLOSE';
        bottom: -10px;
        color: #fff;
      }
    }

    .hamburger[aria-expanded="true"] .hamburger-line {
      width: 18px;
      background-color: transparent;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 31px;
      top: 0px;
      right: -6px;
      left: initial;
      background-color: #fff;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before {
      transform: rotate(35deg);
    }

    .hamburger[aria-expanded="true"] .hamburger-line::after {
      transform: rotate(-35deg);
    }

    .visuallyHidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      margin: -1px;
    }

  }

  .nav-wrap {
    width: 100%;
    height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;

    @include pc() {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-inner {
      display: none;

      @include pc {
        position: relative;
        display: flex;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 20px;
        margin-top: 130px;
      }

      .logo-info {
        width: 270px;

        .logo {
          width: 274px;
          position: absolute;
          top: -160px;
          left: 20px;
        }

        ul {
          margin-top: 40px;

          li {
            a {
              color: #fff;
              margin-bottom: 20px;
              display: block;
              font-size: 11px;
            }
          }
        }
      }
    }

    .nav-sp {
      padding: 70px 15px;

      .other-nav {
        ul {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;

          li {
            width: auto;
            padding: 0;
            border: none;

            a {
              font-size: 13px;
              border: none;
              padding: 0;
            }
          }
        }
      }
    }

    .nav-pc {
      display: none;

      @include pc {
        display: flex;
        flex-shrink: 0;
        margin-right: 8vw;
        padding-right: 8vw;
        border-right: 1px solid #fff;
      }

      ul {
        margin-right: 70px;

        &:last-child {
          margin-right: 0;
        }

        .main-midashi {
          margin-bottom: 2em;

          &>a {
            display: flex;
            align-items: center;
            font-size: 20px;
            color: #fff;
            transition: all 0.4s;

            &:hover {
              opacity: 0.7;
            }

            &::before {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              background: #fff;
              border-radius: 50%;
              margin-right: 10px;
            }
          }

          ul {
            margin-top: 24px;

            li {
              a {
                font-size: 13px;
                display: flex;
                align-items: center;
                color: #fff;
                margin-bottom: 1em;
                transition: all 0.4s;

                &:hover {
                  opacity: 0.7;
                }

                &::before {
                  content: '';
                  display: block;
                  width: 10px;
                  height: 1px;
                  background: #fff;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }

    .copy {
      display: none;

      @include pc {
        display: block;
        font-size: 13px;
        color: #fff;
        position: absolute;
        bottom: 40px;
        right: 40px;
      }
    }
  }


  &.is-top {
    background-color: transparent;
    border-bottom: 1px solid $color-light;

    .nav-line {
      ul {
        li {
          a {
            color: #fff;
          }

          &.under {
            a {
              &::before {
                background: #fff;
              }
            }
          }
        }
      }
    }

    .nav-line ul li.pulldown a::after {
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
    }


    .humburger-wrap {
      .hamburger {
        &::before {
          color: $color-light;
        }
      }
    }

    .hamburger-line::before,
    .hamburger-line::after {
      background-color: $color-light;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 31px;
      top: 0px;
      right: -6px;
      left: initial;
      background-color: #fff;
    }
  }
}